<template>

    <zw-sidebar @shown="shown" :title="$t('invoices.title.paid')">
        <validation-observer tag="div" ref="observer">
            <b-overlay :show="loading" no-wrap></b-overlay>
            <div v-if="!loading">
                <b-row>
                    <b-col sm="12">
                        <label>{{ $t(labelPrefix + 'full_sum') }}</label>
                        <b-form-checkbox @change="togglePaidSum()"
                                         :label-prefix="labelPrefix"
                                         name="full_sum"
                                         :checked="form.full_sum">
                        </b-form-checkbox>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="12">
                        <zw-input-group v-model="form.paid_sum"
                                        name="paid_sum"
                                        :label-prefix="labelPrefix"
                                        :readonly="form.full_sum"
                                        :validate="{ required: !form.full_sum }"
                                        type="number"
                                        min="0"
                                        step="0.01"
                        ></zw-input-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="12">
                        <zw-new-date-group v-model="form.paid_date"
                                           name="paid_date"
                                           :label-prefix="labelPrefix"
                                           format="DD.MM.YYYY"
                                           validate="required"
                        ></zw-new-date-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="12" class="text-xs-center">
                        <b-row align-h="end">
                            <b-col sm="6" class="text-sm-right">
                                <b-button block @click="onSubmit()" variant="primary">
                                    {{ $t('common.button.save') }}
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-col>

                </b-row>
            </div>
        </validation-observer>
    </zw-sidebar>
</template>

<script>
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'
import ZwNewDateGroup from "../../components/ZwNewDateGroup";

export default {
    components: {ZwNewDateGroup},
    mixins: [commonSave],
    data() {
        return {
            loading: true,
            labelPrefix: 'invoices.label.paidform_',
            payload: {},
            callback: null,
            defaultForm: {
                vat: 0,
                position_id: null,
            },
            form: {
                paid_date: '',
                full_sum: true,
                paid_sum: 0,
            },
        }
    },
    methods: {
        shown() {
            this.loading = false
        },
        onSubmit() {
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    this.loading = true
                    this.$store.dispatch('Invoices/markPaidInvoices', {
                        'ids': this.payload,
                        'paid_date': this.form.paid_date,
                        'paid_sum': this.form.paid_sum
                    })
                        .then((response) => {
                            this.commonAfterSave(response)
                        })
                        .catch(errors => {
                            this.$refs['observer'].setErrors(errors)
                            this.showValidationError()
                        })
                        .finally(() => {
                            this.loading = false
                        })
                } else {
                    this.showValidationError()
                }
            })
        },
        togglePaidSum() {
            this.form.full_sum = !this.form.full_sum;
        },
    }
}
</script>